@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Flowbite styles */
@import 'flowbite';

/* Import Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box; /* Menghindari elemen meluberkan lebar karena padding dan border */
}

body {
  padding: 0;
  
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F8F9FA; */
  background-color: white;
  overflow-x: hidden; /* Menghindari overflow horizontal */
  width: 100%; /* Pastikan body lebar 100% */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
