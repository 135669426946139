.produk-swipe-container {
  position: relative;
  background-color: #1D1615;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0px;
 
}

.produk-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.produk-card-container {
  display: flex;
  gap: 30px;
  background-color: #1D1615;
  padding: 60px 16px;  /* Mengurangi padding kiri dan kanan */
  position: relative;
  width: 100%;
  overflow-x: auto; /* Menambahkan scroll horizontal jika diperlukan */
}

.see-all-button {
  position: absolute;
  bottom: 70px;
  right: 20px;
  background-color: #CCAB5C;
  color: #1D1615;
  font-size: 16px;
  font-weight: 500;
  padding: 14px;
  text-transform: uppercase;
  border-radius: 0px; /* Pastikan tombol tidak memiliki border-radius */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 163px;
  height: 46px;
}

.see-all-button:hover {
  background-color: #FF3232;
}

.produk-card {
  flex: 0 0 auto;
  width: 200px;
  height: 326px;
  background-color: #ffffff;
  margin: 0 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0px; /* Pastikan border-radius diatur ke 0 */
}

.produk-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.produk-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 0px; /* Pastikan border-radius diatur ke 0 */
}

.produk-name {
  font-size: 14px;
  font-weight: 400;
 
  text-align: left;
}

.produk-price {
  font-size: 16px;
  font-weight: 700;
 
  text-align: left;
}

.produk-cashback {
  width: 128px;
  height: 25px;
  background-color: rgb(255 215 0 / var(--tw-text-opacity, 1));;
  color: #FF3232;
  font-size: 12px;
  font-weight: 400;
  box-sizing: border-box;
  margin-top: 8px;
  
  
  padding-top: 4px;
}

