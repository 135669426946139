@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Flowbite styles */
@import 'flowbite';

.css-xfeqzu-MuiPaper-root-MuiAppBar-root {
  height: 108px !important;
}

.css-1qt59ix-MuiPaper-root-MuiAppBar-root {
  transition: none !important;
  -webkit-transition: none !important;
  -webkit-transform: none !important;
  height: 108px !important;
}

@media (min-width: 1280px) {
  .xl\:h-80 {
    height: 50rem !important;
  }
}

svg:hover {
  transform: scale(2.0);
}

.text-gold-500 {
  --tw-text-opacity: 1;
  color: rgb(255, 215, 0 / var(--tw-text-opacity, 1));
}

.css-1it3hvs {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  background-color: black !important;
}

.css-1xqfl3j {
  margin-bottom: 0px !important;
}

.gap-8r {
  gap: 8rem;
}

.px-0 {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.top-7 {
  top: 7rem;
}

.App {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8f8f8;
}

.produk-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.produk-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.produk-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 10px;
}

form label {
  margin-bottom: 5px;
}

form input {
  padding: 8px;
  margin-top: 5px;
}

button {
  /* padding: 10px; */
  /* background-color: #4CAF50; */
  color: white;
  border: none;
  cursor: pointer;
}

/* Navbar Styling */
.navbar {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  /* Dark background */
  color: white;
  /* White text for contrast */
}

.navbar-brand {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  /* Ensure brand name stands out */
  text-decoration: none;
  /* Remove underline */
}

.navbar-brand:hover {
  color: #4CAF50;
  /* Add hover effect */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
  /* Space between items */
}

.navbar ul li {
  display: inline;
}

.navbar ul li a {
  color: white;
  /* White text for visibility */
  text-decoration: none;
  /* Remove underline */
  padding: 8px 15px;
  /* Add padding for clickable area */
  border-radius: 4px;
  /* Rounded corners for hover effect */
  transition: background-color 0.3s, color 0.3s;
  /* Smooth hover effect */
}

.navbar ul li a:hover {
  background-color: #4CAF50;
  /* Highlight on hover */
  color: white;
  /* Maintain text contrast */
}

.navbar-cart {
  position: relative;
}

.cart-badge {
  position: relative;
}

.cart-badge .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
  animation: bounceIn 0.5s;
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}


.navbar-user {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: white;
}

.navbar-user-name {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
}

.navbar-user-name:hover {
  color: #4CAF50;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  min-width: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu a,
.dropdown-menu button {
  text-decoration: none;
  color: #333;
  padding: 8px;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
  background-color: #f0f0f0;
  color: #4CAF50;
}

.dropdown-menu button {
  cursor: pointer;
}


.settings-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.profile-section,
.image-section,
.password-section {
  margin-bottom: 20px;
}

.profile-section input,
.password-section input,
.image-section input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  /* padding: 10px 15px; */
  /* background-color: #4CAF50; */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  /* background-color: #45a049; */
  background-color: #2c2424;
  color: white;
}

/* button:hover {
  background-color: transparent;
} */

.produk-detail {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.produk-image {
  flex: 1;
  /* max-width: 50%; */
  text-align: center;
}

.produk-image img {
  width: 691px;
  height: 630px;
  max-height: '100%';
  max-width: '100%';
  border-radius: 0;
  /* Menghilangkan border-radius */
  box-shadow: none;
  /* Menghilangkan shadow */
  background-color: white;
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  padding-left: 196px;
  /* Padding kiri pada thumbnail */
}

.thumbnail-container img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 0px;
  cursor: pointer;
  transition: outline 0.3s ease;
}

.thumbnail-container img.selected {
  outline: 1px solid black;
  /* Outline hitam 1px saat dipilih */
}

.produk-info {
  flex: 2;
  max-width: 59%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
  padding-right: 187px;
  /* Padding kanan pada info produk */
}

.produk-info h3 {
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

.produk-info p {
  font-size: 1rem;
  margin: 5px 0;
}

.produk-info button {
  background-color: #1D1615;
  color: white;
  width: 582px;
  height: 71px;
  padding: 10px;
  border: none;

  cursor: pointer;
  font-size: 32px;
  transition: background-color 0.3s ease;
  margin-top: 46.5px;
}

.produk-info button:hover {
  background-color: #26a008;
}

.color-circle-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.color-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;

}

.color-circle:hover {
  transform: scale(1.1);
}

.color-name {
  text-align: center;
  margin-top: 5px;
  font-size: 0.9rem;

}