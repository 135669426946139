/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears on top */
  }
  
  /* Modal content */
  .modal-content {
    background-color: #F8F9FA;
    padding: 20px;
    border-radius: 0px;
    width: 1054px;
    height: 700px;
    
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Modal actions */
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Input field */
  .password-input {
    width: 100%;
    height: 56px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    font-size: 16px;
    border: none;
  }
  
  /* Buttons */
  .submit-btn, .cancel-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .submit-btn {
    background-color: #1D1615;
    color: white;
    font-size: 24px;
    font-weight: 400;
    width: 100%; /* Menjadikan button full width */
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  
  .cancel-btn {
    font-size: 24px;
    font-weight: 400;
    margin-top: 27px;
    background-color: #ffffff;
    color: #1D1615;
    width: 100%; /* Menjadikan button full width */
  }
  
  .cancel-btn:hover {
    background-color: #e53935;
  }
  