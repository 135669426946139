.css-cmpglg-MuiFormControl-root-MuiTextField-root {
  justify-content: center !important;
  display: flex !important;
  width: 50% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.css-cfq8qh-MuiListItemText-root {
  padding: 16.5px 0px;
}

.css-z2qibm-MuiListItem-root {
  justify-content: center !important;
  display: flex !important;
  /* width: 50% !important; */
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: left !important;
}
.css-rizt0-MuiTypography-root {
  width: 50% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: left !important;
}
.css-xfeqzu-MuiPaper-root-MuiAppBar-root {
  height: 108px !important;
}
