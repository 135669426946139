/* Custom transitions for flowbite-react carousel */
.custom-carousel .flowbite-carousel > .flowbite-carousel-item {
  transition: opacity 2s ease-in-out !important;
  /* Slow fade transition over 2 seconds */
  opacity: 0;
}

.custom-carousel .flowbite-carousel > .flowbite-carousel-item-active {
  opacity: 1;
}

/* Custom transitions for flowbite-react carousel */
.custom-carousel .carousel-item {
  transition: opacity 2s ease-in-out;
  /* Slow fade transition */
  opacity: 0;
}

.custom-carousel .carousel-item.active {
  opacity: 1;
}

/* Replace '.carousel-button' with the actual class used for the buttons */
.carousel-button {
  background-color: transparent; /* Set to your desired background color */
  transition: none; /* Disable transition effects */
}

button:hover {
  background-color: transparent; /* Ensure no background color change on hover */
}

.bottom-5 {
  margin-bottom: 5rem;
}