/* Grid container for products */
.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    gap: 20px; /* Space between items */
    padding: 20px;
  }
  
  /* Styling for each product item */
  .product-item {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Make images responsive */
  .product-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Add spacing below the product title */
  .product-item h3 {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }

  .no-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    text-align: center;
  }
  
  .no-products img {
    width: 300px;
    height: 300px;
  }
  
  .no-products p {
    font-size: 24px;
    padding-top: 30px;
    font-weight: bold;
  }
  

  /* Add to your DetailProdukBrand.css */
.new-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 5px;
    z-index: 1;
  }
  
  