.produk-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .produk-item {
    border: 1px solid #ddd;
    padding: 20px;
    width: 200px;
    text-align: center;
  }
  
  .produk-item img {
    width: 100%;
    height: auto;
  }


/* Mengatur ukuran gambar dan memastikan gambar tidak terlalu besar atau kecil */
.carousel-image {
  width: 100%;  /* Memastikan gambar memenuhi lebar container */
  height: 200px; /* Membatasi tinggi gambar */
  object-fit: contain;
 
}

/* Mengurangi margin antar item carousel */
.react-multi-carousel__item {
  
  margin-right: 10px; /* Sesuaikan margin agar item tidak terpotong */
}

/* Menambahkan padding untuk container carousel agar tampilan lebih baik */
.carousel-container-brand {
  
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

  