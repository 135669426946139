/* CSS untuk container utama */
.carousel-container {
  background-color: white;
  /* margin-top: 120px; */
    padding: 0px;  /* Padding pada container */
  }
  
  /* CSS untuk item carousel agar ada jarak 14px */
  .react-multi-carousel__item {
    margin-right: 34px;  /* Memberikan gap antar item */
  }
  
  /* Memberikan margin kiri pada item pertama agar tidak menempel */
  .react-multi-carousel__item:first-child {
    margin-left: 34px;  /* Memberikan margin kiri pada item pertama */
  }
  
  /* Gaya untuk gambar brand di dalam carousel */
  .carousel-image {
    width: 264px;
    height: 120px;
    object-fit: contain;  /* Atur gambar agar tidak terdistorsi */
  }
  